<template>
    <div class="Rechargecoin" style="padding-bottom: 50px;">

        <header>
            <div class="g-header" style="background: rgb(14, 21, 48)">
                <div class="left" @click="changego">
                    <van-icon name="arrow-left" color="#fff" size="20px" />
                </div>
                <div class="middle fs-18">{{ $t('Bankwithdraw.title') }}</div>
                <div class="right">
                    <div class="bg-icon bg-icon-setting"></div>
                </div>
            </div>
            <div class="blockHeight"></div>
        </header>
        <div class="mescroll g-content">
            <div class="qrcode-content">
                <div class="qrcode-content-container">
                    <div class="qrcode-content-QR">
                        <img src="@/assets/image/bank-info2.png" alt="" srcset="">
                    </div>
                    <!-- <div class="qrcode-content-btn">
                  <button @click="saveQr()">点击下载</button>
                </div>
                <div class="qrcode-content-text">
                  <p style="color: #C0C0C0;">存款地址:</p>
                  <p style="color: #DCDCDC;">{{ selectChainObj.address }}</p>
                </div>
                <div class="qrcode-content-btn1">
                  <button @click="copyClick(selectChainObj.address)">点击复制</button>
                </div> -->
                </div>
            </div>
            <div class="bank-withdrawal">
                <div class="bank-withdrawal-container">
                    

                    <div class="bank-withdrawal-item" style="position: relative;">
                        <label for="amount" color="#F5F5F5">{{$t('Bankwithdraw.amount')}}</label>
                        <input v-model="form.amount" type="text" oninput="value=value.replace(/[^0-9.]/g,'')"
                            :placeholder="$t('Bankwithdraw.yueplaceholder')" id="amount" name="amount">
                            
                        <p class="yue">{{$t('Bankwithdraw.yue')}}:<font>{{ user.money }}</font></p>
                    </div>
                    <div class="bank-withdrawal-item" style="border: none;position: relative;margin-top: 30px;">
                        <label for="selectcurrency" color="#F5F5F5">{{$t('Bankwithdraw.selectcurrency')}}</label>
                        <div class="bank-withdrawal-select" @click="selectCoinClick">
                            <p>
                                <img :src="seletCoinItem.icon" alt="" srcset="">
                                <font>{{ seletCoinItem.currency_to }}</font>
                                <i class="van-icon van-icon-arrow-down"></i>
                            </p>
                        </div>
                        <!-- 选择币 -->
                        <select-coin-pop :coinList="coinList" @emitCoinItemClick="emitCoinItemClick" ref="SelectCoinPop" />
                    </div>
                    <div class="bank-withdrawal-item" style="margin-top: 15px;">
                        <label for="transamount" color="#F5F5F5">{{$t('Bankwithdraw.transamount')}}</label>
                        <input disabled :value="canUserWallet.balance == ''?'':Number(canUserWallet.balance).toFixed(2)" type="text" oninput="value=value.replace(/[^0-9.]/g,'')"
                            :placeholder="$t('Bankwithdraw.yueplaceholder')" id="transamount" name="transamount">
                    </div>
                    <div class="bank-withdrawal-item bank-blank" style="margin-top: 15px;border:none">
                        <label color="#F5F5F5">{{$t('Bankwithdraw.bankinfo')}}</label>
                        <div class="blank-container">
                            <p v-if="infoList">{{$t('Bankwithdraw.acc')}}:{{ infoList.acc }}</p>
                            <p v-if="infoList">{{$t('Bankwithdraw.accname')}}:{{ '***' + infoList.acc_name.substr(infoList.acc_name.length-4,4) }}</p>
                            <p v-if="infoList">{{$t('Bankwithdraw.accbank')}}:{{ infoList.acc_bank }}</p>
                        </div>

                        
                    </div>

                    <!-- <div class="bank-withdrawal-item" style="padding: 15px 0;border:none">
                <label color="#F5F5F5">选择合约</label>
                <van-row type="flex" gutter="20" style="margin-top: 15px;">
                <van-col span="8" v-for="(item, key) in chainList" :key="key" >
                  <van-button plain :type="form.wallet_id == item.id ? 'info' : 'default'" size="small" @click="emitChainItemClick(item)"> {{item.chain}}</van-button>  
                </van-col>
              </van-row>
              </div> -->

                    <!-- <div class="bank-withdrawal-item">
                <label for="amount" color="#F5F5F5">钱包地址</label>
                <input v-model="form.address" type="text" placeholder="请输入您的钱包地址" id="amount" name="amount">
              </div> -->
                    <div class="bank-withdrawal-item" style="border: none;padding: 15px 0;">
                        <button @click="submit()" class="bank-withdrawal-confirm">{{$t('Bankwithdraw.confirmbtn')}}</button>
                    </div>
                </div>
            </div>
        </div>


        <!-- 安全码弹出框提交申请的时候弹出 -->
    <van-popup v-model:show="showsecurity" class="securityalert">
      <div>
        <div class="safe-code">
          <div class="title ta-c c_fff">{{ $t("shuruanquanma") }}</div>
          <div class="paycode">
            <van-password-input
              :value="showsecurityValue"
              :focused="showKeyboard"
              @focus="showKeyboard = true"
            />
          </div>
        </div>
      </div>
    </van-popup>
    <van-number-keyboard
      v-model="showsecurityValue"
      :show="showsecurity"
      @blur="showsecurity = false"
    />



    </div>
</template>
  
<script>
import SelectCoinPop from "@/components/SelectCoinPop.vue";
import coinApi from "@/api/coin"
import userApi from "@/api/user"
export default {
    name: 'rechargecoin',
    components:{
            SelectCoinPop
        },
    data() {
        
        return {
            showsecurity: false,
            showsecurityValue: "",
            showKeyboard: true,
            show: false,
            isTrue: null,
            selectCoinShow: false,
            styObjOne: {
					transform: ''
				},
            selectChainObj: {
                id: 0,
                currency_to: "",
                icon: "",
                rate: 0,
                sell_rate: 0,
            },
            // 币列表
				coinList: [],
                user:[],
                infoList: ''
                ,
            // 选择的币
            seletCoinItem: {
                base_currency: '',
                chain: [],
                icon: ''
            },

            form: {
                currency_id: '', //钱包ID
                amount: '',
                type: '2',
                showsecurityValue:''
            }
        };
    },
    created() {
        this.userInfo()
        this.apiGetCanCunCoinInfoHandel()
        userApi.safetyInfo().then((data) => {
            this.isTrue = data.data.isTrue;
        });
       
    },
    mounted() {
    },
    watch: {
    showsecurityValue(value) {
      if (value.length === 6) {
        // 调用接口
        this.showsecurity = false;
        this.Withdrawal();
      }
    },
  },
    methods: {
        
        selectCoinClick() {
            this.selectCoinShow = !this.selectCoinShow
            if (this.selectCoinShow) {
                this.$refs.SelectCoinPop.onShow()
                this.styObjOne.transform = 'rotate(180deg)'
            } else {
                this.styObjOne.transform = 'rotate(0deg)'
                this.$refs.SelectCoinPop.onClose()
            }
        },
        userInfo(){
            userApi.userInfo().then((data) => {
                this.user = data.msg;
                
                });
                userApi
                .withdrawInfo()
                .then((data) => {
                console.log('++',data.data);
                if(data.data.pay_list.length > 0){
                    this.infoList = data.data.pay_list[0];
                }
                this.$toast.clear();
                })
                .catch((err) => {
                this.$toast.clear();
                this.$toast.fail(err.msg);
                });
        },
        // 选择币触发
        emitCoinItemClick(item) {
            this.$refs.SelectCoinPop.onClose()
            this.selectCoinShow = false
            this.styObjOne.transform = 'rotate(0deg)'
            this.seletCoinItem = item
            this.form.currency_id = this.seletCoinItem.id
            this.linkIndex = 0
            console.log(item)
        },
        apiGetCanCunCoinInfoHandel() {
            coinApi.currency(1).then((data) => {
                console.log(data.data);
                this.coinList = data.data.list
                this.seletCoinItem = data.data.list[0]
                this.form.currency_id = data.data.list[0].id
                console.log(data)
            })
                .catch((err) => {
                    this.$toast.clear();
                    this.$toast.fail(err.msg);
                });
        },
        changego() {
            this.$router.go(-1);
        },
        submit() {
            console.log('bbbbbb',this.form.amount )
            if (!this.form.amount) {
                this.$toast.fail(this.$t('Bankwithdraw.writeamount'));
                return
            }
            if (!this.form.currency_id) {
                this.$toast.fail(this.$t('Bankwithdraw.writecurrency'));
                return
            }
            if(Number(this.form.amount) > Number(this.user.money)){
                this.$toast.fail(this.$t('Bankwithdraw.writesuper'));
                return
            }
            
            // 个人资料完整验证废除
      //判断是否游客
      if (!this.usercrud) {
        userApi.userInfo().then((data) => {
          this.user = data.msg;
          this.$router.push({
            path: "/baseinfo",
            query: {
              name: data.msg.username,
            },
          });
        });
        return;
      }
      
      if (!this.infoList) {
        // 是否有银行卡

        this.$router.push({
          path: "/bankCardManage",
          query: {
            type: 0,
          },
        });
      } else if (!this.isTrue) {
        // 是否有安全码
        this.$router.push({
          path: "/setSecurityCode",
          query: {
            isTrue: this.isTrue,
          },
        });
      } else {
        // 提现请求
        console.log('$$$$$$',this.form.amount)
        if (!this.form.amount) {
          this.$toast(this.$t('Tips.pltxje'));
          return
        } else if (Number(this.canUserWallet.balance) < 100) {
          this.$toast(this.$t('Tips.zdtx'));
          return
        } else {
          this.showsecurity = true;
        }
      }
            
        },
        Withdrawal(){
            let that = this
            this.form.showsecurityValue = this.showsecurityValue
            userApi.bankRecharge(this.form).then((data) => {
                if (data.code) {
                    this.$toast.success(data.msg);
                    setTimeout(function(){
                        that.refresh()
                    },500)
                } else {
                    this.$toast.fail(data.msg);
                }
            })
                .catch((err) => {
                    this.$toast.clear();
                    this.$toast.fail(err.msg);
                });
        },
        refresh(){
            this.$router.go(0);
        }


    },
    computed: {
        i18n() {
            return this.$t(`rechargeCoinTwo`);
        },
        canUserWallet() {
				if(this.form.amount == '') return {
                    balance: '',
					currency: this.seletCoinItem.currency_to
                }
				if (this.seletCoinItem) return {
					balance: this.form.amount * this.seletCoinItem.sell_rate,
					currency: this.seletCoinItem.currency_to
				}
                console.log('$$$$$',this.form.amount * this.seletCoinItem.sell_rate)
				return {
					balance: 0,
					currency: 0
				}
			},
            group_id() {
                return this.$store.state.group_id;
            },
            usercrud() {
                console.log(this.$store.state.usercrud, "12");
                return this.$store.state.usercrud;
            },
    }
}
</script>

<style lang='less' scoped>
.van-number-keyboard {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  background-color: #1a243f;
  -webkit-user-select: none;
  user-select: none;
}
.settingwarp {
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.slide-btn {
    padding: 0.24rem;
}

.van-button--plain.van-button--info {
    color: #1989fa !important;
}

.mescroll {
    // border: red solid 1px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    flex: 1;
}

.qrcode-content {
    // border: red solid 1px;
    width: 100%;
    height: 226px;
    padding: 14px;

    .qrcode-content-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        // border: red solid 1px;
        background-color: #232425;
        width: 100%;
        height: 100%;

        .qrcode-content-QR {
            // border:red solid 1px;
            width: inherit;
            height: inherit;
            margin: 0 auto;

            img {
                width: 100%;
                height: 100%;
            }
        }

        
    }
}

.bank-withdrawal {
    // border: red solid 1px;
    // height: 300px;
    padding: 14px;
    color: #fff;

    .bank-withdrawal-container {
        // border: red solid 1px;
        // height: 60px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .bank-blank{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            // border: red solid 1px;
            .blank-container{
                background-color: #1a243f;
                // border:#1E90FF solid 1px;
                // width: 100%;
                margin-top: 15px;
                height: auto;
                display: flex;
                flex-direction: column;
                padding: 15px;
                p{
                
                    display: inline-block;
                    text-align: left;
                    line-height: 20px;
                    font-size: 15px;
                    margin-top: 5px;
                    // border: red dotted 1px;
                
                }
            }
            
            
        }
        .bank-withdrawal-item {
            // border:red solid 1px;
            height: inherit;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            border-bottom: #F5F5F5 solid 1px;
            .yue{
                font-size: 10px;
                position: absolute;
                bottom:-20px;
                left: 0;
                color: gray;
                // border: red solid 1px;
            }
            .bank-withdrawal-select{
                // border: red solid 1px;
                border: #C0C0C0 solid 1px;
                padding: 5px;
                margin-bottom: 15px;
                margin-top: 15px;
                p{
                    // border: #C0C0C0 solid 1px;
                    display: block;
                    height: 30px;
                    img{
                        display: block;
                        height: 100%;
                        width: auto;
                        float: left;
                        margin-left: 15px;
                        border-radius:30px;
                    }
                    font{
                        line-height: 30px;
                        float: left;
                        margin-left: 15px;
                    }
                    i{
                        display: block;
                        line-height: 30px;
                        float: right;
                        margin-right: 15px;
                    }
                }
            }
            label {
                color: #F5F5F5;
                text-align: left;
                font-size: 15px;
            }

            input {
                // border: red solid 1px;
                display: block;
                color: #F5F5F5;
                font-size: 15px;
                padding: 15px 0;
                text-align: left;
            }

            .van-col {
                button {
                    width: 100%;
                    border-radius: 6px;
                    color: #232425;
                }
            }

            .bank-withdrawal-confirm {
                margin: 0 auto;
                border: #1989fa solid 1px;
                width: 100px;
                height: 30px;
                border-radius: 15px;
                background-color: #1989fa;
            }
        }
    }
}</style>