const tw = {
	jiantizhongwen: '繁體中文',
	xianlu: '線路',
	dianwei: '當前和值',
	wodezichan: '我的資產',
	touzikuaixun: '關於我們',
	jiaoyiyonghu: '使用者',
	jiaoyipinglei: '投注類型',
	danlunhuoli: '單輪獲利',
	jiaoyiriqi: '投注日期',
	dengluhouchakan: '登陸後查看',
	shouye: '首頁',
	jiaoyidating: '交易大廳',
	yucun: '預存',
	zoushi: '走勢',
	wode: '我的',
	baocun: '保存',
	genggai: '更改頭像',
	zuiditouzi: '最低投資',
	zuidijinru: '最低限額',
	jichulicai:'初級廳',
    zishenlicai:'中級廳',
    dashilicai:'高級廳',
    zhuanjialicai:'至尊廳',
	基础理财: '基礎理財',
	资深理财: '資深理財',
	大师理财: '大師理財',
	专家理财: '專家理財',
	huiyuanquanyi: '會員權益',
	zuoriyingli: '昨日盈利',
	daikuanzonge: '貸款總額',
	zongzichan: '總資產',
	chucunjin: '儲存金',
	jinriyingli: '今日盈利',
	zhuanchu: '提款',
	cunru: '存款',
	zhanghuxinxni: '賬戶信息',
	lishizhangdan: '歷史賬單',
	zijinminxi: '賬變記錄',
	renwuzhongxin: '任務中心',
	xiaoxigonggao: '消息公告',
	tuijianfenxiang: '推薦分享',
	daikuanxiangqing: '金融借貸',
	huodongzhongxin: '活動中心',
	jibenziliao: '基本資料',
	dengluzhanghao: '登錄賬號',
	nicheng: '昵称',
	idcard: '身份證號碼',
	congxiangce: '系統默認圖片',
	xitongtu: '系統默認圖片',
	shezhi: '設置',
	genggaixianlu: '更改線路',
	guanyuwomen: '關於我們',
	wodetuandui:'我的團隊',
    yonghuming: '使用者名稱',
    shouyi: '今日收益',
    zongrenshu: '總人數',
	xitongshezhi: '系統設置',
	yuyanshezhi: '語言設置',
	tuichudenglu: '退出登錄',
	bangdingyinhangka: '綁定銀行卡',
	bangdingxuniqianbao: '綁定虛擬錢包',
	anquanma: '安全碼',
	xiugailenglumima: '修改登錄密碼',
	yinhangkaguanli: '銀行卡管理',
	tianjiayinhangka: '添加銀行卡',
	xuniqianbaoguanli: '虛擬錢包管理',
	tianjiaxuniqianbao: ' 添加虛擬錢包',
	chiakren: '持卡人',
	yinhangkakahao: '銀行戶口號',
	yinhangmingcheng: '銀行名稱',
	yhkTps: '請綁定持卡人本人的銀行卡',
	nixiqianbaodizhi: '虛擬錢包地址',
	xuniqianbaoleixing: '虛擬錢包類型',
	xnqbTps: ' 溫馨提示：請填寫USDT地址類型，如：trc20、erc20、omni。',
	plchikaren: '請輸入持卡人姓名',
	plkahao: '請輸入正確的銀行戶口號',
	plyinhangmingcheng: '請輸入銀行名稱',
	plqbdz: '請輸入虛擬錢包地址',
	plqblx: '請輸入虛擬錢包類型',
	shezhianquanma: '重置安全碼',
	yuananquanma: '原安全碼',
	xinanquanma: '新安全碼',
	planquanma: '請輸入原安全碼',
	plxinanquanma: '請輸入新安全碼',
	queren: '確認',
	quxiao: '取消',
	anquanmaTps: '請設置安全碼，請勿與銀行卡密碼相同',
	anquanmaTpsTow: '尊敬的客戶，此安全碼是您轉出時的密碼，為了安全起見，請盡量不要與登錄密碼相同',
	xiugaidenglu: '修改登錄密碼',
	jiumima: '舊密碼',
	xinmima: '新密碼',
	querenmima: '確認密碼',
	pljiumima: '請輸入舊密碼',
	plxinmima: '請輸入新密碼',
	plquerenmima: '請再次輸入密碼',
	wancheng: '完成',
	kaishitouzi: '開始投資',
	kaiTps: '當前線路無法使用時，可嘗試切換其他線路',
	zuiyou: '最優',
	dangaqianxianlu: '當前線路',
	dangqianbanben: '當前版本',
	banquansuoyou: '版權所有',
	yinglizhendong: '盈利震動',
	gensuixitong: '跟隨系統',
	zhuanchujine: '轉出金額',
	zhuanrujine: '轉入金額',
	plzhuanru: '請輸入轉入金額',
	plzhuanchu: '請輸入轉出金額',
	zhuanchuTps: '如有疑問請聯系客服',
	xuanzeyh: '請選擇提款銀行卡',
	xuanzehb: '請選擇提款虛擬錢包',
	tijiaoshenqing: '提交申請',
	shuruanquanma: '請輸入安全碼',
	xianshangcunru: '線上存入',
	saomacunru: 'USDT',
	wangyincunru: "網銀存入",
	shijian: '時間',
	upshijian: '開始時間',
	downshijian: '結束時間',
	wuTps: '暫無相關數據~',
	jiazaicx: '重新加載',
	di: '第',
	lun: '輪',
	weiyingli: '虧損',
	yishouli: '已受理',
	zhuangtai: '狀態',
	chongzhi: '重置',
	riqi: '日期',
	lianxikefu: '聯繫客服',
	CapActive: {
		chongzhi: '存款',
		tixian: '提款',
		goumai: '购买',
		yingli: '獲利',
		kuisun: '虧損',
		zengsong: '贈送',
		weitongguo: '未通過',
		yitongguo: '已通過',
		yijujue: '已拒絕',
		all: '全部'
	},
	zhanneixiaoxi: "站内消息",
	pingtaigonggao: '平臺公告',
	fuzhilanjie: "復制鏈接",
	denglu: '登錄',
	jizhumima: '記住密碼',
	wangjimima: '忘記密碼',
	youkedenglu: '遊客登錄',
	zhucezhanghao: "立即註冊",
	plusername: '請輸入用戶名',
	plpassword: '請輸入密碼',
	replpassword: '請輸再次輸入密碼',
	plinvitecode: '請輸入邀請碼',
	plidcard: '請輸入身份證號碼',
	tuijianyaoqingma: '推薦邀請碼',
	wanchengzhuce: '完成註冊',
	yiyouzhanghao: '已有賬戶',
	qingdenglu: '請登錄',
	zhuce: '注册',
	remenhuati: '熱門話題',
	news1: '平台簡介',
	news2: '玩法介紹',
	news3: '活動',
	renliulan: '人瀏覽',
	hezhi: '和值',
	lunshu: '輪數',

	做多: '大',
	做空: '小',
	多单: '大單',
	多双: '大雙',
	空单: '小單',
	空双: '小雙',
	平单: '單',
	平双: '雙',
	极阴: '极小',
	极阳: '极大',
	qi: '期',
	juli: '距離',
	lunjiezhi: '輪截至',
	yifengpan: '已封盤',
	yiguanbi: '已關閉',
	yonghu: '用戶',
	danlunjiaoyi: '投注金額',
	caozuo: '操作',
	pljoin: '加入聊天室成功',
	pltingshou: '產品已停售',
	gengou: "跟購",
	quedinggengou: "確定跟購嗎",
	wanjia: "玩家",
	leibie: "類別",
	jine: "金額",
	gouru: '購入',
	zanweigouru: '暫未購入',
	qigou: '起購',
	gourushuliang: '購入數量',
	changgui: '常規',
	shuzi: '數位',
	yinglihezhi: '盈利和值',
	shouyijieshao: '收益介紹',
	lijicunru: '立即存入',
	kaihaojieguo: '開號結果',
	kaihaoqushi: '開號趨勢',
	chanpingshuoming: '產品說明',
	qingchuhuancun: '清除緩存',
	youke: '游客',
	gongxi: '恭喜 ',
	zai: ' 在 ',
	yingli: ' 盈利 ',
	xiadanchenggong: '下單成功',
	jiazaizhong: '加載中...',
	guanbitg: '已關閉，停止購入',
	xiangoudl: '只能跟購當前輪',
	liaotiantip: '系統已禁言，請選擇購入',
	tishi: '提示',
	zhidao: '知道了',
	zhuanchusb: '轉出失敗',
	zhuanchusbs: '餘額不足，轉出失敗',
	buyxq: '購入詳情',
	orderNo: '賬單編號',
	plan_name: '廳房信息',
	buyxx: '購入信息',
	haoxx: '選號詳情',
	buysj: '購入時間',
	yilou: '遺漏',
	fzjqb: '已復製到粘貼板',
	chanpin1: '開售時間：每3.5分鐘為一輪，當日10:00AM開盤，次日02:00AM停盤。 ',
	chanpin2: '每輪有3個0-9的數字，3個數字相加為最終結果，可以投資結果大小單雙大單大雙小單小雙極大極小以及數字。 ',
	Tips: {
		wjmm: '忘記密碼請聯繫客服修改!',
		dlcg: '登錄成功',
		tjcg: '提交成功',
		zanwu: '暫無數據',
		buyizhi: '兩次密碼不一致',
		zcbz: '您的資產不足請存入',
		pltxje: '請輸入提現金額',
		zdtx: '最低提現100',
		youke: '尊敬的遊客您好：請您註冊正式會員後操作。 ',
		szcg: '設置成功',
	},
	Recharge: {
		title: '投注金額',
		usdtrecharge: 'USDT存款',
		btcrecharge: 'BTC存款',
		ethrecharge: 'ETH存款',
		usdtwithdrawal: 'USDT提款',
		bankrecharge: '銀行存款',
		bankwithdrawal: '銀行提款'
	},
	Rechargecoin: {
		title: 'USDT存款',
		downbtn: '點擊下載',
		downurl: '存款地址',
		copybtn: '點擊複製',
		amount: '額度',
		amountplaceholder: '請輸入額度',
		selectcontract: '儲值網絡',
		walletaddress: '錢包地址',
		walletaddressplaceholder: '請輸入您的錢包地址',
		confirmbtn: '點擊確認',
		sharecode: '分享二維碼.png',
		writeamount: '請填寫數額',
		writeaddress: '請填寫地址',
		transamount: '轉換USDT',
	},
	Withdrawcoin: {
		title: 'USDT提款',
		currency: '幣種',
		selectcontract: '選擇合約',
		amount: '額度',
		yue: '餘額',
		yueplaceholder: '請輸入額度',
		walletaddress: '錢包地址',
		walletaddressplaceholder: '請輸入您的錢包地址',
		confirmbtn: '點擊確認',
		sharecode: '分享二維碼.png',
		writeamount: '請填寫數額',
		writeaddress: '請填寫地址',
		writesuper: '提現超出餘額',
	},
	Bankrecharge: {
		title: '銀行存款',
		amount: '數額',
		yue: '餘額',
		selectcurrency: '選擇貨幣',
		transamount: '轉換後的金額',
		confirmbtn: '點擊確認',
		yueplaceholder: '請輸入額度',
		writeamount: '請填寫數額',
		writecurrency: '請選擇幣種',
	},
	Bankwithdraw: {
		title: '銀行卡提款',
		amount: '數額',
		yue: '餘額',
		selectcurrency: '選擇貨幣',
		transamount: '轉換後的金額',
		confirmbtn: '點擊確認',
		yueplaceholder: '請輸入額度',
		writeamount: '請填寫數額',
		writecurrency: '請選擇幣種',
		writesuper: '提現超出餘額',
		bankinfo: '銀行信息',
		acc: '賬戶',
		accname: '賬戶名稱',
		accbank: '賬戶銀行',
	},
	Loandetail: {
		title1: '想向我們獲得及時貸款嗎？',
		title2: '請按照以下簡單步驟操作！',
		troop1: '1、郵件申請',
		troop2: '2、參觀我們的辦公室',
		content1: '該訪問我們的辦公室並為您自己創建一份定制的貸款合同',
		troop3: '3、簽署貸款協議',
		content2: '了解條款和條件，然後簽署貸款協議',
		troop4: '4、領取現金',
		content3: '一小時內收到您的現金貸款！',
		content4: '貸款類型個人貸款，提供必要的個人和財務信息，如收入、徵信記錄等。審核與評估評估您的信用風險，決定是否批准貸款。批准與條件貸款申請獲批准，您將收到正式的貸款批准通知，其中包含貸款金額、利率、還款期限等條件。簽署合同.放款一次性放款.還款*依合約約定一次性還款。年利率1%起，為了避免文化上的差異，所有形式都以郵件申請！',
		confirmtext: '立即申請貸款',
	}
}
export default tw;