import { createI18n } from 'vue-i18n'
import home from '../api/home'
import zh from './config/zh'
import tw from './config/tw'
import en from './config/en'
import ja from './config/ja'
import kr from './config/kr'
import ge from './config/ge'
import ti from './config/ti'
import vi from './config/vi'
// console.log(1000,JSON.stringify(zh))
const i18n  = createI18n({
    legacy: false, // composition API
    globalInjection:true,
    locale:localStorage.getItem('locale')||'vi',
    messages:{
        zh,
        tw,
		en,
        ja,
        kr,
        ge,
        ti,
        vi,
    },
 })
 home.setLanguage(i18n.global.locale.value)
 export default i18n;