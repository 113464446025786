<template>
    <div class="container">
        <header>
        <div class="g-header" style="background: rgb(14, 21, 48)">
          <div class="left" @click="changego">
            <van-icon name="arrow-left" color="#fff" size="20px" />
          </div>
          <div class="middle fs-18">{{$t('daikuanxiangqing')}}</div>
          <div class="right">
            <div class="bg-icon bg-icon-setting"></div>
          </div>
        </div>
        <div class="blockHeight"></div>
      </header>
      <div class="container-content">
            <h1>{{ $t('Loandetail.title1') }}</h1>
            <h1>{{ $t('Loandetail.title2') }}</h1>
            <div class="container-content-detail">
                <h2 class="container-send-title">{{ $t('Loandetail.troop1') }}</h2>
                <div class="container-content-item">
                    <div class="container-content-item-img">
                        <img src="@/assets/image/detail/01-new.png" alt="">
                    </div>
                </div>
                <p class="container-content-text"></p>
            </div>
            <div class="container-content-detail">
                <h2 class="container-send-title">{{ $t('Loandetail.troop2') }}</h2>
                <div class="container-content-item">
                    <div class="container-content-item-img">
                        <img src="@/assets/image/detail/02-new.png" alt="">
                    </div>
                </div>
                <p class="container-content-text">
                        {{ $t('Loandetail.content1') }}
                </p>
            </div>
            <div class="container-content-detail">
                <h2 class="container-send-title">{{ $t('Loandetail.troop3') }}</h2>
                <div class="container-content-item">
                    <div class="container-content-item-img">
                        <img src="@/assets/image/detail/03-new.png" alt="">
                    </div>
                </div>
                <p class="container-content-text">
                    {{ $t('Loandetail.content2') }}
                </p>
            </div>
            <div class="container-content-detail">
                <h2 class="container-send-title">{{ $t('Loandetail.troop4') }}</h2>
                <div class="container-content-item">
                    <div class="container-content-item-img">
                        <img src="@/assets/image/detail/04-new.png" alt="">
                    </div>
                </div>
                <p class="container-content-text">
                    {{ $t('Loandetail.content3') }}
                </p>
            </div>
            <div class="container-content-detail">
                <p class="container-content-text" style="color: #FFFAF0;border:#778899 solid 1px">{{ $t('Loandetail.content4') }}</p>
            </div>
            <div class="container-content-detail">
                <button class="container-content-button" @click="kefu()">{{ $t('Loandetail.confirmtext') }}</button>
            </div>
      </div>
    </div>
</template>

<script>
export default {
    name: "Loan",
    data(){
        return {

        }
    },
    created(){

    },
    methods:{
        changego() {
            this.$router.go(-1);
        },
        kefu(){
            this.$router.push({
                path: "/kf",
            });
        }
    }
}
</script>

<style lang="less" scoped>
.container{
    // border:red solid 1px;
    width: wv;
    height: auto;
    overflow-y: auto;
    flex: 1;
}
.container-content{
    // border: red solid 1px;
    margin-bottom: 30px;
}
.container-content-detail{
    // border: red solid 1px;
    // padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.container-send-title{
    display: block;
    line-height: 48px;
}
.container-content-item{
    // border: red solid 1px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.container-content-item-img{
    // border: red solid 1px;
    width: 3.38rem;
    height: 3.38rem;
    margin: 0 auto;
    line-height: 3.38rem;
    background-color: #fff;
}
.container-content-item-img img{
    width: 80%;
    height: 80%;
}
.container-content-text{
    // border: red solid 1px;
    padding: 25px;
    word-break: break-all;
    word-wrap: break-word;
    text-align: center;
    text-indent: 20px;
}
.container-content-button{
    // border: red solid 1px;
    display: block;
    height: 48px;
    width: 80%;
    margin: 50px auto;
    background-color: #FFA500;
    color: #fff;
    line-height: 48px;
    font-size: 20px;
}
</style>