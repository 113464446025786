import request from "@/utils/request";
export default {
  // 积分日志
 //   充值页面
  wallet(product_id) {
    return request.post("/api/wallet/index", {product_id:product_id});
  },
  currency() {
    return request.post("/api/wallet/currencyList", {});
  },
};
