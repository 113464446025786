const zh={
	jiantizhongwen:'简体中文',
    xianlu:'线路',
    dianwei:'当前和值',
    wodezichan:'我的资产',
    touzikuaixun:'关于我们',
    jiaoyiyonghu:'用户',
    jiaoyipinglei:'投注类型',
    danlunhuoli:'单轮获利',
    jiaoyiriqi:'投注日期',
    dengluhouchakan:'登陆后查看',
    shouye:'首页',
    jiaoyidating:'交易大厅',
    yucun:'预存',
    zoushi:'走势',
    wode:'我的',
    baocun: '保存',
    genggai: '更改头像',
    zuiditouzi:'最低投资',
    zuidijinru:'最低限额',
    jichulicai:'初级厅',
    zishenlicai:'中级厅',
    dashilicai:'高级厅',
    zhuanjialicai:'至尊厅',
	基础理财:'基础理财',
    资深理财:'资深理财',
    大师理财:'大师理财',
    专家理财:'专家理财',
    huiyuanquanyi:'会员权益',
    zuoriyingli:'昨日盈利',
    daikuanzonge: '贷款总额',
    zongzichan:'总资产',
    chucunjin:'储存金',
    jinriyingli:'今日盈利',
    zhuanchu:'提款',
    cunru:'存款',
    zhanghuxinxni:'账户信息',
    lishizhangdan:'历史账单',
    zijinminxi:'账变记录',
    renwuzhongxin:'任务中心',
    xiaoxigonggao:'消息公告',
    tuijianfenxiang:'推荐分享',
    daikuanxiangqing:'金融借贷',
    huodongzhongxin:'活动中心',
    jibenziliao:'基本资料',
    dengluzhanghao:'登录账号',
    nicheng:'昵称',
    idcard: '身份证号码',
    congxiangce: '从相册选择',
    xitongtu: '系统默认图片',
    shezhi:'设置',
    genggaixianlu:'更改线路',
    guanyuwomen:'关于我们',
    wodetuandui:'我的团队',
    yonghuming: '用户名',
    shouyi: '今日收益',
    zongrenshu: '总人数',
    xitongshezhi:'系统设置',
    yuyanshezhi:'语言设置',
    tuichudenglu:'退出登录',
    bangdingyinhangka:'绑定银行卡',
    bangdingxuniqianbao:'绑定虚拟钱包',
    anquanma:'安全码',
    xiugailenglumima:'修改登陆密码',
    yinhangkaguanli:'银行卡管理',
    tianjiayinhangka:'添加银行卡',
    xuniqianbaoguanli:'虚拟钱包管理',
    tianjiaxuniqianbao:'添加虚拟钱包',
    chiakren:'持卡人',
    yinhangkakahao:'银行卡卡号',
    yinhangmingcheng:'银行名称',
    yhkTps:'请绑定持卡人本人的银行卡',
    nixiqianbaodizhi:'虚拟钱包地址',
    xuniqianbaoleixing:'虚拟钱包类型',
    xnqbTps:' 温馨提示：请填写USDT地址类型，如：trc20、erc20、omni ',
    plchikaren:'请输入持卡人',
    plkahao:'请输入持卡人卡号',
    plyinhangmingcheng:'请输入银行名称',
    plqbdz:'请输入虚拟钱包地址',
    plqblx:'请输入虚拟钱包类型',
    shezhianquanma:'设置安全码',
    yuananquanma:'原安全码',
    xinanquanma:'新安全码',
    planquanma:'请输入原安全码',
    plxinanquanma:'請输入新安全码',
    queren:'确认',
	quxiao:'取消',
    anquanmaTps:' 请设置安全码，请勿与银行卡密码相同',
    anquanmaTpsTow:' 尊敬的客户，此安全码是您转出时的密码，为了安全起见，请尽量不要与登录密码相同',
    xiugaidenglu:'修改登录密码',
    jiumima:'旧密码',
    xinmima:'新密码',
    querenmima:'确认密码',
    pljiumima:'请输入旧密码',
    plxinmima:'请输入新密码',
    plquerenmima:'请再次输入密码',
    wancheng:'完成',
    kaishitouzi:'开始投资',
    kaiTps:'当前线路无法使用时，可尝试切换其他线路',
    zuiyou:'最优',
    dangaqianxianlu:'当前线路',
    dangqianbanben:'当前版本',
    banquansuoyou:'版权所有',
    yinglizhendong:'盈利震动',
    gensuixitong:'跟随系统',
    zhuanchujine:'转出金额',
    plzhuanchu:'请输入转出金额',
    zhuanrujine:'转入金额',
    plzhuanru:'请输入转入金额',
    zhuanchuTps:'温馨提示：如有疑问请联系平台客服。',
    xuanzeyh:'请选择提款银行卡',
    xuanzehb:'请选择提款虚拟钱包',
    tijiaoshenqing:'提交申请',
    shuruanquanma:'请输入安全码',
    xianshangcunru:'线上存入',
    saomacunru:'USDT',
    wangyincunru:"银行存入",
    shijian:'时间',
    upshijian:'开始时间',
    downshijian:'结束时间',
    wuTps:'暂无相关数据~',
    jiazaicx:'重新加载',
    di:'第',
    lun:'轮',
    weiyingli:'亏损',
    yishouli:'已受理',
    zhuangtai:'状态',
    chongzhi:'重置',
    riqi:'日期',
    lianxikefu: '联系客服',
    CapActive:{
        chongzhi:'存款',
        tixian:'提款',
        goumai:'购买',
        yingli:'盈利',
        kuisun: '亏损',
        zengsong:'赠送',
        weitongguo:'未通过',
        yitongguo:'已通过',
        yijujue:'已拒绝',
        all: '全部'
    },
    zhanneixiaoxi:"站内消息",
    pingtaigonggao:'平台公告',
    fuzhilanjie:"复制链接",
    denglu:'登录',
    zhuce:'注册',
    jizhumima:'记住密码',
    wangjimima:'忘记密码',
    youkedenglu:'游客登陆',
    zhucezhanghao:"注册账户",
    plusername:'请输入用户名',
    plpassword:'请输入密码',
    replpassword:'请输再次输入密码',
    plinvitecode:'请输入邀请码',
    plidcard: '请输入身份证号码',
    tuijianyaoqingma: '推荐邀请码',
    wanchengzhuce:'完成注册',
    yiyouzhanghao:'已有账户',
    qingdenglu:'请登录',
    remenhuati:'热门话题',
    news1:'平台简介',
    news2:'玩法介绍',
    news3:'活动',
    renliulan:'人浏览',
    hezhi:'和值',
    lunshu:'轮数',

       做多:'大',
        做空:'小',
        多单:'大单',
        多双:'大双',
        空单:'小单',
        空双:'小双',
        平单:'单',
        平双:'双',
        极阴:'极小',
        极阳:'极大',
    qi:'期',
    juli:'距离',
    lunjiezhi:'轮截至',
    yifengpan:'已封盘',
    yiguanbi:'已关闭',
    yonghu:'用户',
    danlunjiaoyi:'投注金额',
    caozuo:'操作',
    pljoin:'加入聊天室成功',
    pltingshou:'产品已停售',
    gengou:"跟购",
	quedinggengou:"确定跟购吗",
	wanjia:"玩家",
	leibie:"类别",
	jine:"金额",
    gouru:'购入',
    zanweigouru:'暂未购入',
    qigou:'起购',
    gourushuliang:'购入数量',
    changgui:'常规',
    shuzi:'数字',
    yinglihezhi:'盈利和值',
    shouyijieshao:'收益介绍',
    lijicunru:'立即存入',
    kaihaojieguo:'开号结果',
    kaihaoqushi:'开号趋势',
    chanpingshuoming:'产品说明',
    qingchuhuancun:'清除缓存',
    youke:'游客',
	gongxi:'恭喜 ',
	zai:' 在 ',
	yingli:' 盈利 ',
	xiadanchenggong:'下单成功',
	jiazaizhong:'加载中...',
	guanbitg:'已关闭，停止购入',
	xiangoudl:'只能跟购当前轮',
	liaotiantip:'系统已禁言，请选择购入',
	tishi:'提示',
	zhidao:'知道了',
	zhuanchusb:'转出失败',
	zhuanchusbs:'余额不足，转出失败',
	buyxq:'购入详情',
	orderNo:'账单编号',
	plan_name:'厅房信息',
	buyxx:'购入信息',
	haoxx:'选号详情',
	buysj:'购入时间',
	yilou:'遗漏',
	fzjqb:'已复制到粘贴板',
	chanpin1:'开售时间：每3.5分钟为一轮，当日10:00AM开盘，次日02:00AM停盘。',
	chanpin2:'每轮有3个0-9的数字，3个数字相加为最终结果，可以投资结果大小单双大单大双小单小双极大极小以及数字。',
	Tips:{
        wjmm:'忘记密码请联系客服修改!',
        dlcg:'登录成功',
        tjcg:'提交成功',
        zanwu:'暂无数据',
        buyizhi:'两次密码不一致',
        zcbz:'您的资产不足请存入',
        pltxje:'请输入提现金额',
        zdtx:'最低提现100',
		youke:'尊敬的游客您好：请您注册正式会员后操作。',
		szcg:'设置成功',
    },
    Recharge:{
        title: '存款',
        usdtrecharge: 'USDT存款',
        btcrecharge: 'BTC存款',
        ethrecharge: 'ETH存款',
        usdtwithdrawal: 'USDT提款',
        bankrecharge: '银行存款',
        bankwithdrawal: '银行提款'
    }
    ,
    Rechargecoin:{
        title:'USDT存款',
        downbtn: '点击下载',
        downurl: '存款地址',
        copybtn: '点击复制',
        amount: '额度',
        amountplaceholder: '请输入额度',
        selectcontract: '充值网络',
        walletaddress: '钱包地址',
        walletaddressplaceholder: '请输入您的钱包地址',
        confirmbtn: '点击确认',
        sharecode: '分享二维码.png',
        writeamount: '请填写数额',
        writeaddress: '请填写地址',
        transamount: '转换USDT',
    },
    Withdrawcoin:{
        title: 'USDT提款',
        currency: '币种',
        selectcontract: '选择合约',
        amount: '额度',
        yue: '余额',
        yueplaceholder: '请输入额度',
        walletaddress: '钱包地址',
        walletaddressplaceholder: '请输入您的钱包地址',
        confirmbtn: '点击确认',
        sharecode: '分享二维码.png',
        writeamount: '请填写数额',
        writeaddress: '请填写地址',
        writesuper: '提现超出余额',
    },
    Bankrecharge:{
        title:'银行存款',
        amount: '数额', 
        yue: '余额',
        selectcurrency: '选择货币',
        transamount: '转换后的金额',
        confirmbtn: '点击确认',
        yueplaceholder: '请输入额度',
        writeamount: '请填写数额',
        writecurrency: '请选择币种',
    },
    Bankwithdraw:{
        title:'银行卡提款',
        amount: '数额', 
        yue: '余额',
        selectcurrency: '选择货币',
        transamount: '转换后的金额',
        confirmbtn: '点击确认',
        yueplaceholder: '请输入额度',
        writeamount: '请填写数额',
        writecurrency: '请选择币种',
        writesuper: '提现超出余额',
        bankinfo: '银行信息',
        acc: '账户',
        accname: '账户名称',
        accbank: '账户银行',
    },
    Loandetail:{
        title1: '想向我们获得及时贷款吗？',
        title2: '请按照以下简单步骤操作！',
        troop1: '1、邮件申请',
        troop2: '2、参观我们的办公室',
        content1: '该访问我们的办公室并为您自己创建一份定制的贷款合同',
        troop3: '3、签署贷款协议',
        content2: '了解条款和条件，然后签署贷款协议',
        troop4: '4、领取现金',
        content3: '一小时内收到您的现金贷款！',
        content4: '贷款类型个人贷款，提供必要的个人和财务信息，如收入、征信记录等。审核与评估评估您的信用风险，决定是否批准贷款。批准与条件贷款申请获批准，您将收到正式的贷款批准通知，其中包含贷款金额、利率、还款期限等条件。签署合同.放款一次性放款.还款*根据合同约定一次性还款。年利率1%起，为了避免文化上的差异，所有形式以邮件申请！',
        confirmtext: '立即申请贷款',
    }
}
export default zh;