const vi = {
	jiantizhongwen: "Tiếng Trung giản thể",
	xianlu: "Đường truyền",
	dianwei: "Giá trị tổng hiện tại",
	wodezichan: "Tài sản của tôi",
	touzikuaixun: "Về chúng tôi",
	jiaoyiyonghu: "Người dùng",
	jiaoyipinglei: "Loại",
	danlunhuoli: "Lợi nhuận vòng đơn",
	jiaoyiriqi: "Ngày giao dịch",
	dengluhouchakan: "Đăng nhập để xem",
	shouye: "Trang chủ",
	jiaoyidating: "Phòng giao dịch",
	yucun: "Đặt trước",
	zoushi: "Xu hướng",
	wode: "Cá nhân",
	baocun: 'Lưu',
	genggai: 'Thay đổi ảnh đại diện',
	zuiditouzi: "Đầu tư tối thiểu",
	zuidijinru: "Vốn tối thiểu",
	jichulicai: "Phòng sơ cấp",
	zishenlicai: "Phòng trung cấp",
	dashilicai: "Phòng Superior",
	zhuanjialicai: "Phòng cao cấp",
	基础理财: "Quản lý tài chính cơ bản",
	资深理财: "Quản lý tài chính cao cấp",
	大师理财: "Chuyên viên tài chính",
	专家理财: "Chuyên gia tài chính",
	huiyuanquanyi: "Thông tin cá nhân",
	zuoriyingli: "Lợi nhuận trong ngày",
	daikuanzonge: 'Tổng vay',
	zongzichan: "Tổng tài sản",
	chucunjin: "Tiết kiệm",
	jinriyingli: "Lợi nhuận trong ngày",
	zhuanchu: "Rút tiền",
	cunru: "Nạp tiền",
	zhanghuxinxni: "Thông tin tài khoản",
	lishizhangdan: "Lịch sử giao dịch",
	zijinminxi: "Chi tiết giao dịch",
	renwuzhongxin: "Trung tâm nhiệm vụ",
	xiaoxigonggao: "Thông báo",
	tuijianfenxiang: "Chia sẻ",
	daikuanxiangqing:'Chi tiết khoản vay',
	huodongzhongxin: "Trung tâm hoạt động",
	jibenziliao: "Dữ liệu cơ bản",
	dengluzhanghao: "Đăng nhập tài khoản",
	nicheng: "Tên tài khoản",
	idcard: 'Mã số',
	congxiangce: 'Chọn từ album',
    xitongtu: 'Hình ảnh mặc định của hệ thống',
	shezhi: "Cài đặt",
	genggaixianlu: "Thay đổi đường truyền",
	guanyuwomen: "Giới thiệu",
	wodetuandui:'Đội của tôi',
    yonghuming: 'Tên tài khoản',
    shouyi: 'Lợi nhuận',
    zongrenshu: 'Tổng số người',
	xitongshezhi: "Cài đặt hệ thống",
	yuyanshezhi: "Cài đặt ngôn ngữ",
	tuichudenglu: "Đăng xuất",
	bangdingyinhangka: "Liên kết thẻ ngân hàng",
	bangdingxuniqianbao: "Liên kết ví ảo",
	anquanma: "Mã bảo vệ",
	xiugailenglumima: "Thay đổi mật khẩu đăng nhập",
	yinhangkaguanli: "Quản lý thẻ ngân hàng",
	tianjiayinhangka: "Thêm thẻ ngân hàng",
	xuniqianbaoguanli: "Quản lý ví ảo",
	tianjiaxuniqianbao: "Thêm ví ảo",
	chiakren: "Tên tài khoản",
	yinhangkakahao: "Số tài khoản",
	yinhangmingcheng: "Tên ngân hàng",
	yhkTps: "Vui lòng liên kết thẻ ngân hàng chính chủ tài khoản",
	nixiqianbaodizhi: "Địa chỉ ví ảo",
	xuniqianbaoleixing: "Loại ví ảo",
	xnqbTps: "Nhắc nhở: Vui lòng điền loại địa chỉ USDT, chẳng hạn như: trc20, erc20, omni",
	plchikaren: "Vui lòng nhập tên tài khoản ngân hàng",
	plkahao: "Vui lòng nhập số tài khoản ngân hàng",
	plyinhangmingcheng: "Vui lòng nhập tên ngân hàng",
	plqbdz: "Vui lòng nhập địa chỉ ví ảo",
	plqblx: "Vui lòng nhập loại ví ảo",
	shezhianquanma: "Đặt mã bảo mật",
	yuananquanma: "Mã bảo mật ban đầu",
	xinanquanma: "Mã bảo mật mới",
	planquanma: "Vui lòng nhập mã bảo mật ban đầu",
	plxinanquanma: "Vui lòng nhập mã bảo mật mới",
	queren: "Xác nhận",
	quxiao: "Hủy bỏ",
	anquanmaTps: "Quý khách đặt mã bảo mật vui lòng không đặt mã bảo mật trùng với tài khoản ngân hàng",
	anquanmaTpsTow: " Quý khách hàng thân mến! Mã bảo mật là mật khẩu của bạn khi rút tiền, vì lý do bảo mật, vui lòng không đặt mã bảo mật trùng mật khẩu đăng nhập",
	xiugaidenglu: "Thay đổi mật khẩu đăng nhập",
	jiumima: "Mật khẩu cũ",
	xinmima: "Mật khẩu mới",
	querenmima: "Xác nhận mật khẩu",
	pljiumima: "Vui lòng nhập mật khẩu cũ",
	plxinmima: "Vui lòng nhập mật khẩu mới",
	plquerenmima: "Vui lòng nhập lại mật khẩu",
	wancheng: "Hoàn thành",
	kaishitouzi: "Bắt đầu đầu tư",
	kaiTps: "Khi đường truyền hiện tại không khả dụng, hãy thử chuyển sang đường truyền khác",
	zuiyou: "Tốt nhất",
	dangaqianxianlu: "Đường truyền hiện tại",
	dangqianbanben: "Phiên bản hiện tại",
	banquansuoyou: "Đã đăng ký bản quyền",
	yinglizhendong: "Báo rung",
	gensuixitong: "Theo dõi hệ thống",
	zhuanchujine: "Số tiền chuyển khoản",
	plzhuanchu: "Vui lòng nhập số tiền chuyển",
	zhuanrujine: "Số tiền chuyển khoản",
	plzhuanru: "Vui lòng nhập số tiền chuyển",
	zhuanchuTps: "Nhắc nhở: Nếu bạn có bất kỳ thắc mắc nào, vui lòng liên hệ với dịch vụ chăm sóc khách hàng.",
	xuanzeyh: "Vui lòng chọn thẻ ngân hàng rút tiền",
	xuanzehb: "Vui lòng chọn ví ảo để rút tiền",
	tijiaoshenqing: "Xác nhận",
	shuruanquanma: "Vui lòng nhập mã bảo vệ",
	xianshangcunru: "Nạp tiền trực tuyến",
	saomacunru: "USDT",
	wangyincunru: "Nạp tiền từ ngân hàng",
	shijian: "Thời gian",
	upshijian: "Thời gian bắt đầu",
	downshijian: "Thời gian kết thúc",
	wuTps: "Không có dữ liệu liên quan~",
	jiazaicx: "Tải lại",
	di: "Vòng",
	lun: "Vòng",
	weiyingli: "Thua",
	yishouli: "Đã được chấp nhận",
	zhuangtai: "Tình trạng",
	chongzhi: "Cài lại",
	riqi: "Ngày",
	lianxikefu: 'Liên hệ dịch vụ chăm sóc khách hàng',
	CapActive: {
		chongzhi: "Nạp tiền",
		tixian: "Rút tiền",
		goumai: "Mua",
		yingli: "Lợi nhuận",
		kuisun: 'Thua',
		zengsong: "Tặng ",
		weitongguo: "Không vượt qua",
		yitongguo: "Đã duyệt",
		yijujue: "Đã từ chối",
		all: 'Tất cả'
	},
	zhanneixiaoxi: "Tin tức nền tảng",
	pingtaigonggao: "Thông báo nền tảng",
	fuzhilanjie: "Sao chép đường dẫn",
	denglu: "Đăng nhập",
	zhuce: "Đăng ký",
	jizhumima: "Nhớ mật khẩu",
	wangjimima: "Quên mật khẩu",
	youkedenglu: "Truy cập đăng nhập",
	zhucezhanghao: "Đăng ký tài khoản",
	plusername: "Vui lòng nhập tên người dùng",
	plpassword: "Vui lòng nhập mật khẩu",
	replpassword:'Vui lòng nhập lại mật khẩu',
	plinvitecode: "Vui lòng nhập mã thư mời",
	plidcard: 'Vui lòng nhập số CMND',
	tuijianyaoqingma: 'Mã thư mời được đề xuất',
	wanchengzhuce: "Đăng ký hoàn tất",
	yiyouzhanghao: "Đã có tài khoản",
	qingdenglu: "Đăng nhập",
	remenhuati: "Hoạt động nổi bật ",
	news1: "Giới thiệu nền tảng",
	news2: "Cách chơi",
	news3: "Hoạt động",
	renliulan: "Lưu lượng truy cập",
	hezhi: "Giá trị tổng",
	lunshu: "Số vòng",
	做多: "Lớn",
	做空: "Nhỏ",
	多单: "Đơn lớn",
	多双: "Đôi lớn",
	空单: "Đơn nhỏ",
	空双: "Đôi nhỏ",
	平单: "Đơn",
	平双: "Đôi",
	极阴: "Cực nhỏ",
	极阳: "Cực lớn",
	qi: "Vòng",
	juli: "Vòng tiếp theo",
	lunjiezhi: "làm tròn lên",          
	yifengpan: "Đã đóng",
	yiguanbi: "Đã đóng",
	yonghu: "Người dùng",
	danlunjiaoyi: "Số tiền",
	caozuo: "Vận hành",
	pljoin: "Tham gia phòng chat thành công",
	pltingshou: "Đã ngừng",
	gengou: "Theo dõi mua hàng",
	quedinggengou: "Bạn có chắc chắn muốn theo dõi giao dịch mua không?",
	wanjia: "Người chơi",
	leibie: "Loại",
	jine: "Số tiền",
	gouru: "Mua",
	zanweigouru: "Chưa mua",
	qigou: "Mua",
	gourushuliang: "Số lượng mua",
	changgui: "Thông thường",
	shuzi: "Con số",
	yinglihezhi: "Lợi nhuận và giá trị",
	shouyijieshao: "Giới thiệu lợi ích",
	lijicunru: "Gửi tiền ngay bây giờ",
	kaihaojieguo: "Kết quả số",
	kaihaoqushi: "Xu hướng mở số",
	chanpingshuoming: "Mô tả Sản phẩm",
	qingchuhuancun: "Xóa bộ nhớ cache",
	youke: "Khách xem",
	gongxi: "Chúc mừng",
	zai: "Hiện hữu",
	yingli: "Lợi nhuận",
	xiadanchenggong: "Mua thành công",
	jiazaizhong: "Đang tải...",
	guanbitg: "Đã đóng mua",
	xiangoudl: "Bạn chỉ có thể mua vòng hiện tại",
	liaotiantip: "Hãy chọn mua",
	tishi: "Gợi ý",
	zhidao: "Biết",
	zhuanchusb: "Không thể rút tiền",
	zhuanchusbs: "Số dư không đủ, rút tiền không thành công",
	buyxq: "Chi tiết mua",
	orderNo: "Số giao dịch",
	plan_name: "Thông tin phòng",
	buyxx: "Mua",
	haoxx: "Chi tiết chọn số",
	buysj: "Thời gian mua",
	yilou: "Bỏ sót",
	fzjqb: "Sao chép vào clipboard",
	chanpin1: "Thời gian mở thưởng: Cứ 3,5 phút có một vòng quay, mở vào 10h00 cùng ngày và kết thúc vào 02h00 ngày hôm sau.",
	chanpin2: "Có 3 số từ 0-9 trong mỗi vòng và tổng của 3 số là kết quả cuối cùng. Bạn có thể đặt đơn, đôi, lớn, nhỏ, đơn nhỏ, đơn lớn, đôi nhỏ, đôi lớn, cực nhỏ, cực lớn.",
	Tips: {
		wjmm: "Nếu bạn quên mật khẩu, vui lòng liên hệ bộ phận chăm sóc khách hàng để lấy lại mật khẩu!",
		dlcg: "Đăng nhập thành công",
		tjcg: "Gửi thành công",
		zanwu: "Không có dữ liệu",
		buyizhi: "Hai mật khẩu không khớp",
		zcbz: "Tài sản của bạn không đủ, hãy nạp tiền",
		pltxje: "Vui lòng nhập số tiền rút",
		zdtx: "Rút tiền tối thiểu 100",
		youke: "Kính gửi khách truy cập, vui lòng hoạt động sau khi đăng ký làm thành viên chính thức.",
		szcg: "Thiết lập thành công"
	},
	Recharge: {
		title: "Nạp tiền",
		usdtrecharge: "Nạp USDT",
		btcrecharge: 'Nạp BTC',
		ethrecharge: 'Nạp ETH',
		usdtwithdrawal: "Rút USDT",
		bankrecharge: "Nạp từ ngân hàng",
		bankwithdrawal: "Rút về ngân hàng"
	},
	Rechargecoin: {
		title: "Nạp USDT",
		downbtn: "Bấm để tải xuống",
		downurl: "Địa chỉ gửi tiền",
		copybtn: "Nhấn để sao chép",
		amount: "Số tiền",
		amountplaceholder: "Vui lòng nhập số tiền",
		selectcontract: "Loại ví ảo",
		walletaddress: "Địa chỉ ví ảo",
		walletaddressplaceholder: "Vui lòng nhập địa chỉ ví của bạn",
		confirmbtn: "Nhấn để xác nhận",
		sharecode: "Chia sẻ mã QR.png",
		writeamount: "Vui lòng điền số tiền",
		writeaddress: "Vui lòng điền địa chỉ",
		transamount: 'Chuyển đổi USDT',
	},
	Withdrawcoin: {
		title: "Rút USDT",
		currency: "Số tiền",
		selectcontract: "Chọn loại ví",
		amount: "Số tiền",
		yue: "TỔNG TÀI SẢN",
		yueplaceholder: "Vui lòng nhập số tiền",
		walletaddress: "Địa chỉ ví",
		walletaddressplaceholder: "Vui lòng nhập địa chỉ ví của bạn",
		confirmbtn: "Nhấn để xác nhận",
		sharecode: "Chia sẻ mã QR.png",
		writeamount: "Vui lòng điền số tiền",
		writeaddress: "Vui lòng điền địa chỉ",
		writesuper: "Rút tiền vượt quá số dư"
	},
	Bankrecharge: {
		title: "Nạp từ ngân hàng",
		amount: "Số tiền",
		yue: "TỔNG TÀI SẢN",
		selectcurrency: "Chọn tiền tệ",
		transamount: "Số tiền quy đổi",
		confirmbtn: "Nhấn để xác nhận",
		yueplaceholder: "Vui lòng nhập số tiền",
		writeamount: "Vui lòng điền số tiền",
		writecurrency: "Vui lòng chọn đơn vị tiền tệ"
	},
	Bankwithdraw: {
		title: "Rút tiền về ngân hàng",
		amount: "Số tiền",
		yue: "TỔNG TÀI SẢN",
		selectcurrency: "Chọn tiền tệ",
		transamount: "Số tiền quy đổi",
		confirmbtn: "Nhấn để xác nhận",
		yueplaceholder: "Vui lòng nhập số tiền",
		writeamount: "Vui lòng điền số tiền",
		writecurrency: "Vui lòng chọn đơn vị tiền tệ",
		writesuper: "Rút tiền vượt quá số dư",
		bankinfo: "Thông tin ngân hàng",
		acc: "Tài khoản",
		accname: "Tên tài khoản",
		accbank: "Tài khoản ngân hàng"
	},
    Loandetail:{
        title1: 'Bạn muốn nhận khoản vay ngay lập tức từ chúng tôi?',
        title2: 'Thực hiện theo các bước đơn giản dưới đây!',
        troop1: '1. Ứng dụng email',
        troop2: '2. Ghé thăm văn phòng của chúng tôi',
        content1: 'Đã đến lúc ghé thăm văn phòng của chúng tôi và tạo một hợp đồng cho vay tùy chỉnh cho chính bạn',
        troop3: '3. Ký hợp đồng vay',
        content2: 'Biết các điều khoản và điều kiện, sau đó ký hợp đồng cho vay',
        troop4: '4. Nhận tiền mặt',
        content3: 'Nhận khoản vay tiền mặt của bạn trong vòng một giờ!',
        content4: 'Loại khoản vay cá nhân, cung cấp thông tin cá nhân và tài chính cần thiết như thu nhập, lịch sử tín dụng, vv... Xem xét và đánh giá rủi ro tín dụng của bạn sau đó quyết định có chấp thuận khoản vay hay không. Phê duyệt và điều kiện sau khi đơn xin vay của bạn được phê duyệt, bạn sẽ nhận được thông báo phê duyệt khoản vay chính thức với các thông tin như số tiền vay, lãi suất, thời hạn trả nợ, vv.. Ký kết hợp đồng. Khoản vay là khoản vay một lần. Trả nợ* là khoản hoàn trả một lần theo hợp đồng. Lãi suất hàng năm bắt đầu từ 1%. Để tránh sự khác biệt về văn hóa, tất cả các hình thức đăng ký đều qua email!',
        confirmtext: 'Đăng ký vay ngay',
    }
}
export default vi;