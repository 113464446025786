<template>
  <div class="c-coin-pop" v-show="selectCoinShow">
    <div @click="itemClick(item, index)" class="c-coin-pop-item g-flex-align-center" :class="index == selectIndex ? 'c-coin-pop-item-active': ''" v-for="(item,index) in coinList" :key="index">
      <img :src="item.icon" alt="">
      <div class="c-coin-pop-item-right">
        <span class="c-coin-pop-item-title">{{ item.currency_to }}</span>
        <p class="c-coin-pop-item-name"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    coinList: {
      type: Array,
      default() {
        return [
        ]
      }
    }
  },
  data() {
    return {
      selectIndex: 0,
      selectCoinShow: false,
    }
  },
  methods: {
    onShow() {
      this.selectCoinShow = true
    },
    onClose() {
      this.selectCoinShow = false
    },
    itemClick(item, index) {
      this.selectIndex = index
      this.$emit('emitCoinItemClick', item)
    }
  }
}
</script>

<style lang='less'>
.c-coin-pop {
  width: 100%;
  position: absolute;
  max-height: 248px;
  border: 1px solid #e9ebf2;
  border-radius: 2px;
  -webkit-box-shadow: 0 8px 12px 0 rgb(14 18 27 / 6%);
  box-shadow: 0 8px 12px 0 rgb(14 18 27 / 6%);
  margin-top: 4px;
  right: -1px;
  z-index: 99;
  overflow: auto;
  bottom: 13px;
  background: #040925;
  transform: translateY(100%);
  margin-bottom: 100px;
  .c-coin-pop-item {
    cursor: pointer;
    margin: 0;
    user-select: none;
    padding: 10px 16px;
    line-height: inherit;
    &.c-coin-pop-item-active {
      background: rgba(245,246,250,.5);
    } 
    &:hover {
      background: rgba(245,246,250,.5);
    }
    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
      margin-right: 8px;
      border-radius: 50%;
      float: left;
    }
    .c-coin-pop-item-right {
      .c-coin-pop-item-title {
        font-size: 14px;
        line-height: 20px;
        color: #fff;
        float: left;
      } 
      .c-coin-pop-item-name {
        display: block;
        font-size: 12px;
        line-height: 20px;
        height: 20px;
        color: #959dad;
      } 
    }

  }
}
</style>