<template>
 <div class="c_upload">
  <!---上传-->
    <van-uploader v-show="false" :id="idString" ref="uploadImg"  :before-read="beforeRead" :after-read="afterRead">
    
    </van-uploader>
 </div>
</template>

<script>
// import { apiUpload } from '@/utils/api.js'
import apiUpload from '@/api/upload'
export default {
  
  props: {
    idString: {
      type: String,
      default() {
        return 'c-upload'
      }
    }
  },
  data() {
    return {
      limitPng: ['image/jpeg','image/jpg','image/png','image/JPEG','image/JPG','image/PNG'],
    }
  },
  methods: {
    onUploadClick() {
      // document.getElementById('upp').click()
      this.$refs.uploadImg.chooseFile()
    },
    // 上传之前
    beforeRead(file) {
      console.log('&&&&&&&&',file)
      this.$toast.loading({
        message: this.$t('jiazaizhong'),
        forbidClick: true,
        duration: 0,
      });
      // if(this.limitPng.indexOf(file.type) === -1) {
      //   this.$toast('请上传jpeg、jpg、png格式的图片')
      //   return false
      // }
      return true
    },

    // 上传
    async afterRead(file) {
      
      // formdata提交
      let formData = new FormData()
      formData.append('file', file.file)
      apiUpload.uploadImg(formData).then((data) => {
            if(data.code == 1){
              this.$toast.success(data.msg)
              this.$emit('emitUploadSuccess', data.data)
            }
            this.$toast.clear();
            })
            .catch((err) => {
            this.$toast.clear();
            this.$toast.fail(err.msg);
            });
     
      
      
    },
  }
}
</script>

<style lang='less'>

</style>